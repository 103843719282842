@import '../../../_design-tokens';

.notification-channels-modal-container {
  width: 30rem;
}

.notification-channels-modal-content {
  padding: $var-spacing-vertical-medium $var-spacing-horizontal-x-large 2rem $var-spacing-horizontal-x-large;
}

.notification-channels-modal-content .slds-form-element {
  padding-top: $var-spacing-vertical-small;
}

.notification-channels-modal-image {
  width: $square-icon-utility-large;
  height: $square-icon-utility-large;
  vertical-align: initial;
}

// Copy what adding slds-combobox__input-entity-icon class to img would do
.slds-combobox__form-element.slds-input-has-icon.slds-input-has-icon_left-right .notification-channels-modal-image {
  position: absolute;
  top: 50%;
  left: calc(0.25rem + 1px);
  transform: translateY(-50%);
}

.notification-channels-modal-dependent-rules-list {
  font-weight: bold;
  padding: $var-spacing-small $var-spacing-horizontal-large $var-spacing-small $var-spacing-horizontal-large;
}
