@import '../../_design-tokens';

.loading-div {
  height: calc(100vh - 175px);
  background-color: $table-color-background-header;
  border-bottom: 1px solid $color-border;
}

.slds-page-header__name-switcher {
  margin: 0px;
}
