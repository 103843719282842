@import '../../_design-tokens';

.suppression-rule-modal-container {
  width: 52rem;
  max-width: 52rem;
}

.suppression-rule-modal-content {
  padding: $var-spacing-vertical-medium $var-spacing-horizontal-large;
}

.suppression-rule-modal-nameinput {
  width: 50%;
}

.suppression-rule-modal-reasoninput {
  width: 75%;
}

.suppression-rule-modal-reasoncount {
  color: $color-text-placeholder;
  font-size: $var-font-size-1;
  padding-top: $var-spacing-vertical-xx-small;
}

.suppression-rule-modal-content .slds-section__content {
  margin-left: $var-spacing-horizontal-xx-large;
  margin-right: $var-spacing-horizontal-xx-large;
  margin-bottom: $var-spacing-vertical-large;
}

.suppression-rule-modal-content .slds-form-element {
  padding-top: $var-spacing-vertical-x-small;
}

.suppression-rule-modal-datetime-error {
  font-size: $var-font-size-2;
  color: $color-text-error;
  margin-top: $var-spacing-vertical-small;
  margin-left: $var-spacing-horizontal-small;
}
