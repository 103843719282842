@import '../../../../design-tokens';

.alert-details-container {
  width: 60rem;
  max-width: 60rem;
}

.alert-details-content {
  padding: $var-spacing-vertical-medium $var-spacing-horizontal-large;
}

.alert-details-content .slds-section:nth-child(n + 2) {
  padding-top: $var-spacing-vertical-medium;
}

.alert-details-form {
  padding-left: $var-spacing-horizontal-small;
  padding-right: $var-spacing-horizontal-small;
}

.slds-spinner_container {
  position: relative;
}

.slds-spinner_inline {
  position: absolute;
}

.alert-details-description {
  white-space: break-spaces;
}

#events-section-expanded-section-content {
  padding-left: $var-spacing-horizontal-small;
  padding-right: $var-spacing-horizontal-small;
}

.slds-table_header-fixed_container {
  border: 1px solid rgb(221, 219, 218);
  border-style: solid;
}

.no-events {
  color: $color-text-placeholder;
  font-size: $var-font-size-2;
}
