@import '../../_design-tokens';

.rule-expression-builder-custom-logic-tooltip {
  box-shadow: 0px 1px 3px 2px rgba(0, 0, 0, 0.2);
}

.rule-expression-builder .slds-expression__custom-logic {
  padding-top: 0 !important;
}

.rule-expression-builder .slds-expression__row {
  margin-top: $var-spacing-vertical-x-small;
  padding-right: 40px;
}

.rule-expression-builder .slds-expression__legend {
  position: relative;
  top: $var-spacing-vertical-xx-small;
}

.rule-expression-builder .slds-form-element {
  padding-top: $var-spacing-vertical-x-small;
}

#rule-criteria-group-add-group-button,
#rule-criteria-group-take-action-trigger-listbox li.slds-listbox__item:nth-child(4),
#rule-criteria-group-take-action-trigger-listbox li.slds-listbox__item:nth-child(5) {
  display: none;
}

.rule-expression-builder-spinner {
  display: flex;
  margin: $var-spacing-vertical-large 0 $var-spacing-vertical-large $var-spacing-horizontal-xx-small;
}

.rule-expression-builder-spinner .slds-spinner {
  position: relative;
  top: 10px;
}

.rule-expression-builder-spinner-message {
  margin-left: $var-spacing-horizontal-small;
}

.rule-condition-row .slds-col:first-child .slds-listbox__item:last-child {
  color: $color-text-link;
}

.custom-logic-invalid {
  color: $color-text-error;
  padding-left: $var-spacing-horizontal-x-small;
}

.rule-expression-builder-error {
  font-size: $var-font-size-2;
  color: $color-text-error;
  margin-top: $var-spacing-vertical-medium;
  margin-left: $var-spacing-horizontal-medium;
  list-style: disc;
}

.rule-expression-builder-note {
  color: $color-text-placeholder;
  padding-top: $var-spacing-vertical-xx-small;
}
