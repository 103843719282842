@import '../../_design-tokens';

.rules-popup {
  background-color: #fff;
  box-shadow: 0px 1px 3px 2px rgba(0, 0, 0, 0.2);
  max-width: 512px;
}

.rules-popup .slds-popover__body {
  padding: 0;
}

.rules-display {
  color: $color-text-default;
}

.rules-display-row {
  margin-left: $var-spacing-horizontal-small;
  margin-right: $var-spacing-horizontal-small;
}

.rules-header {
  font-size: $font-size-5;
  padding: $var-spacing-vertical-x-small $var-spacing-vertical-small;
  border-bottom: 1px solid $color-border;
}

.rules-logic {
  width: 25%;
  color: #888;
}

.rules-hr-after {
  border-bottom: 1px solid #666;
}

.rules-display-cell {
  display: inline-block;
}

.rules-display-header {
  font-size: 1.1rem;
  color: #333;
}

.rules-low-text {
  font-size: $font-size-3;
  color: $color-text-weak;
  padding-top: $var-spacing-vertical-small;
}

.rules-bold-text {
  font-size: $font-size-3;
  font-weight: $font-weight-bold;
  padding-top: $var-spacing-vertical-xx-small;
  margin-bottom: $var-spacing-vertical-small !important;
  display: -webkit-box;
  -webkit-line-clamp: 12;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.rules-cell-space-to-icon {
  padding-right: $var-spacing-horizontal-xx-small;
}

.notifier-pill {
  white-space: normal !important;
}

.notifier-pills-type {
  vertical-align: baseline;
  min-height: $square-icon-utility-medium;
  min-width: $square-icon-utility-medium;
}

.notifier-pill .slds-pill + .slds-pill {
  margin-left: 0;
}

.notifier-pill .slds-pill {
  margin-right: $var-spacing-horizontal-xx-small;
  margin-top: $var-spacing-horizontal-xxx-small;
  margin-bottom: $var-spacing-horizontal-xxx-small;
}
