@import '../../_design-tokens';

.notification-channels-type {
  width: $square-icon-utility-medium;
  height: $square-icon-utility-medium;
}

.notification-channels-typeLabel {
  vertical-align: middle;
  margin-left: $var-spacing-horizontal-xxx-small;
}

.notification-channels-list tbody tr th {
  padding: $var-spacing-horizontal-x-small $var-spacing-vertical-medium;
}

.notification-channels-list-actions {
  min-width: 8rem;
}
