@import '../_design-tokens';

.app-header {
  height: $height-header;
  color: $color-gray-11;
  flex-shrink: 0;
}

.app-logo {
  height: $square-icon-medium;
  width: $square-icon-medium;
}

.app-title {
  font-weight: $font-weight-bold;
}
