@import '../../../../design-tokens';

.alert-edit-modal-container {
  width: 30rem;
}

.alert-edit-modal-content {
  padding: $var-spacing-vertical-medium $var-spacing-horizontal-x-large 2rem $var-spacing-horizontal-x-large;
}

.alert-edit-modal-content .slds-form-element {
  padding-top: $var-spacing-vertical-small;
}
