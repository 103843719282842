@import './_design-tokens';
div.App {
  background-image: url('./images/lightning_blue_background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.app-content-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: $var-spacing-vertical-small $var-spacing-horizontal-small $var-spacing-vertical-small
    $var-spacing-horizontal-small;
}

.flex-column-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.tokenCard,
.empty-card {
  text-align: center;
}

.empty-card-image {
  height: 12.5rem;
  width: 18.75rem;
}

.footer-bar {
  background-color: $color-background;
  height: 0.5rem;
  border-bottom-left-radius: $border-radius-medium;
  border-bottom-right-radius: $border-radius-medium;
  border: 1px solid $color-border;
  border-top: none;
}

.list-info-summary {
  font-size: $var-font-size-2;
}

.errorItem:first-child {
  padding-top: 0;
}
.errorItem {
  padding-top: $var-spacing-vertical-small;
  display: block;
}

// Override z-index to 8001 to allow the combobox dropdown to
// be displayed on top of the modal which has a z-index of 8000.
// By default, SLDS specifies a z-index of 7000.
.modal-combobox-dropdown {
  z-index: 8001;
}
.modal-combobox-dropdown .slds-listbox__option {
  display: flex;
  align-items: center;
}
.modal-combobox-dropdown .slds-listbox__option-text--entity,
.slds-listbox__option-text_entity {
  margin-bottom: $var-spacing-vertical-xx-small;
}

///////////////////////////////////////////
// SLDS overrides to line up left edge of table header first column with page header
.slds-table tbody tr td:first-child,
.slds-table thead tr th:first-child a {
  padding-left: $var-spacing-horizontal-medium;
}

.slds-table tbody tr th:first-child {
  padding-left: $var-spacing-horizontal-medium;
}

.slds-table_header-fixed_container {
  border-bottom: none !important;
  border-radius: 0px !important;
}

.slds-tabs_default__item {
  font-size: $font-size-5;
}

.slds-page-header.slds-page-header_joined {
  border: 1px solid $color-border;
  border-radius: $border-radius-medium $border-radius-medium 0 0;
  padding-bottom: $var-spacing-vertical-x-small;
}
