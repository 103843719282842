@import '../../../design-tokens';

.alerts-spinner {
  display: flex;
  align-items: flex-end;
  margin-right: 8px;
  padding-top: 7px;
}

.alerts-spinner .slds-spinner {
  position: relative;
  top: 5px;
}

.alerts-spinner-message {
  font-size: $font-size-2;
  margin-left: 0.75rem;
}
