@import '../../../design-tokens';

.status-active {
  color: $color-text-destructive;
}

.status-acknowledge {
  color: #b36200;
}

.status-clear {
  color: #3e3e3c;
}

.alerts-list-actions {
  min-width: 8rem;
}
