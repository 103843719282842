@import '../../_design-tokens';

.no-tokens-image {
  height: 12.5rem;
  width: 18.75rem;
}

.token-table {
  width: $size-x-large;
  border-radius: $border-radius-small;
}
