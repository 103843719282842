@import '../../../../_design-tokens';

.service-editor-service-catalog-icon-container {
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  top: -2px;
}

.service-editor-service-catalog-icon-svg {
  width: 100% !important;
  height: 100% !important;
}
