@import '../../_design-tokens';

.token-created-input {
  width: $size-large;
}

.token-success {
  background-color: $color-background-success;
}

.copied-toast {
  line-height: $line-height-heading;
}

.inline-flex-plz {
  display: inline-flex !important;
}

.fake-footer {
  height: $height-header;
}

.created-token-footer {
  padding: 0;
  margin-left: $var-spacing-horizontal-xx-large;
  margin-right: $var-spacing-horizontal-xx-large;
}
