@import '../../_design-tokens';

.notification-tooltip {
  background-color: #fff;
  box-shadow: 0px 1px 3px 2px rgba(0, 0, 0, 0.2);
}

.notification-tooltip .slds-popover__body {
  padding: 0.75rem;
}

.notification-tooltip-display {
  color: $color-text-default;
}

.notification-rule-modal-container {
  width: 52rem;
  max-width: 52rem;
}

.modal-combobox-dropdown .notification-rule-modal-image {
  width: $square-icon-utility-large;
  height: $square-icon-utility-large;
  vertical-align: initial;
}

.notification-rule-modal-nameinput {
  width: 50%;
}

.notification-rule-modal-channelscombo {
  width: 50%;
}

.notification-rule-modal-content {
  padding: $var-spacing-vertical-medium $var-spacing-horizontal-large;
}

.notification-rule-modal-content .slds-form-element {
  padding-top: $var-spacing-vertical-x-small;
}

.notification-rule-modal-content .slds-section__content {
  margin-left: $var-spacing-horizontal-xx-large;
  margin-right: $var-spacing-horizontal-xx-large;
  margin-bottom: $var-spacing-vertical-large;
}

.notification-rule-modal-content .slds-form-element .input-default-channel-checkbox {
  padding-top: 0px;
}

.notification-rule-modal-content .slds-listbox__option {
  display: flex;
  align-items: center;
}

.embedded-create-channel-toast {
  display: inline-flex !important;
  -webkit-box-align: center;
  align-items: center;
  position: absolute;
  color: $color-gray-1;
  font-weight: $font-weight-light;
  border-radius: $border-radius-medium;
  margin: $spacing-x-small;
  padding: $var-spacing-vertical-small $var-spacing-xx-large $var-spacing-vertical-small $var-spacing-horizontal-large;
  width: 30rem;
  text-align: left;
  -webkit-box-pack: start;
  justify-content: flex-start;
  top: 40px;
  left: calc(50% - 240px);
  z-index: 9002;
}
