@import '../../_design-tokens';

.service-detail-card .slds-card__body {
  margin-top: 0rem;
  margin-bottom: 0rem;
  padding-top: $var-spacing-vertical-small;
  padding-bottom: $var-spacing-vertical-small;
  padding-left: $var-spacing-horizontal-medium;
  padding-right: $var-spacing-horizontal-medium;
}

.service-detail-card .slds-card__body,
.service-detail-card .slds-tabs_default,
.service-detail-card .slds-tabs_default__content {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.service-detail-card .slds-tabs_default__content {
  padding: $var-spacing-vertical-small 0 0 0;
}

.service-detail-dropdown {
  min-width: 8rem;
  left: -97px !important;
}
