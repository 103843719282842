@import '../../../../design-tokens';

.checkboxEx-checkbox {
  padding-top: 0px !important;
}

.checkboxEx-disabled-text {
  color: $color-text-weak;
  font-size: $font-size-3;
  padding-top: $var-spacing-vertical-xx-small;
  font-style: italic;
}
