@import '../../_design-tokens';

.suppression-rule-modal-datetimepicker {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 75%;
  gap: $var-spacing-vertical-x-small;
}

.suppression-rule-modal-datepicker {
  flex: 1;
}

.suppression-rule-modal-timeinput {
  flex: 1;
}

.suppression-rule-modal-timeinput .slds-input {
  line-height: $line-height-text;
}

.suppression-rule-modal-utctime {
  flex: 1;
  color: $color-text-placeholder;
  padding-top: 36px;
}
