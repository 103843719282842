@import '../../../../_design-tokens.scss';

.channel-editor-type-product-tag .slds-icon_container {
  width: $square-icon-utility-large;
  height: $square-icon-utility-large;
}

.channel-editor-type-product-tag .channel-editor-type-product-tag-icon-svg {
  width: 100% !important;
  height: 100% !important;
}
