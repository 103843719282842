@import '../../../_design-tokens';

.service-modal-container {
  width: 30rem;
}

.service-modal-content {
  padding: $var-spacing-vertical-medium $var-spacing-horizontal-x-large 2rem $var-spacing-horizontal-x-large;
}

.service-modal-content .slds-form-element {
  padding-top: $var-spacing-vertical-small;
}

.service-modal-back-button {
  float: left;
}

.service-modal-content .input-service-name {
  padding-top: 0px !important;
}

.service-modal-content .input-channel-name {
  padding-top: 0px !important;
}

.service-modal-create-channel-heading {
  font-size: $font-size-5;
  color: $color-text-default;
  font-weight: bold;
  text-align: center;
  padding-bottom: $var-spacing-vertical-small;
}

.service-modal-create-channel-description {
  font-size: $font-size-3;
  color: $color-text-default;
  text-align: center;
  padding-bottom: $var-spacing-vertical-large;
}
