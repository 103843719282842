@import '../../../../design-tokens';

.slds-listbox__item .user-combobox-profile-img {
  width: $square-icon-medium;
  height: $square-icon-medium;
  vertical-align: initial;
  border-radius: 2px;
}

.slds-combobox_container .user-combobox-profile-img {
  width: $square-icon-utility-large;
  height: $square-icon-utility-large;
  vertical-align: initial;
  border-radius: 2px;
  position: absolute;
  margin-top: 4px;
  margin-left: 5px;
}
